import React from 'react';

import Segment from '@components/segment';
import Layout from '@components/layout';
import Seo from '@components/seo';
import HeroAnimationCenter from '@components/sections/heroAnimationCenter/heroAnimationCenter';

import SocialProofFeedST from '@components/staticSections/socialProofFeedST/socialProofFeedST';
import { LANG_US_EN } from '@/src/common/consts/lang';
import { HERO_IMAGE_ILLUSTRATION_HOLDING_PHONE_APP } from '@/src/common/consts/hero';

const DownloadPage = () => {
  const heroId = 'download';

  return (
    <Layout
      backgroundColor="#172D47!important"
      textColor="#06E55C!important"
      dataTheme="white"
      HeaderFloatShow
      headerStyle="flex flex--center text-center container container--lg"
      headerContainerStyle="HeaderFloatContainer--position-relative"
      showFooter
      lang="us"
    >
      <Segment id={process.env.SEGMENT_MARKETING_LANDING_PAGES_US} />

      <Seo
        title="Get started with Joint Academy"
        description="Enter your phone number and receive a SMS to download the Joint Academy mobile app"
        language="en"
      />

      <HeroAnimationCenter
        title="Get started with Joint Academy"
        titleClassName="bold"
        text="Enter your phone number and receive a SMS to download the Joint Academy mobile app"
        elementId={heroId}
        imageVariant={HERO_IMAGE_ILLUSTRATION_HOLDING_PHONE_APP}
        containerSize="md"
        showSmsMobile
      />

      <SocialProofFeedST lang={LANG_US_EN} sectionClassName="margin-bottom--lg" hideText />
    </Layout>
  );
};

export default DownloadPage;
